'use strict';

window.insight = {};

// inViewport
(
    function($, win) {
      $.fn.inViewport = function(cb) {
        return this.each(function(i, el) {
          function insight_vispx() {
            var H = $(this).height(),
                r = el.getBoundingClientRect(), t = r.top, b = r.bottom;
            return cb.call(el, Math.max(0, t > 0 ? H - t : (
                b < H ? b : H
            )));
          }

          insight_vispx();
          $(win).on('resize scroll', insight_vispx);
        });
      };
    }(jQuery, window)
);
