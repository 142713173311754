(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        SmartMenu: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            var self = this;
            self.smart_menu();

            $(window).on('resize', function() {
              self.smart_menu();
            });
          },

          smart_menu: function() {
            $('#primary-menu').
                find('.menu-item-has-children, .page_item_has_children').
                each(function() {
                  if ($(this).children('ul').first().offset().left + 245 >=
                      $(window).width()) {
                    $(this).addClass('hover_left');
                  }
                });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
