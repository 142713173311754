(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        Page404: {
          init: function() {
            this.build();
            return this;
          },

          build: function() {
            var self = this;
            self.fix_height();

            jQuery('.content-404-back').on('click', function(e) {
              e.preventDefault();
              self.go_back();
            });

            jQuery(window).on('resize', function() {
              self.fix_height();
            });
          },

          fix_height: function() {
            var wh = jQuery(window).height();
            jQuery('body.error404 .content-area').height(wh);
          },

          go_back: function() {
            window.history.back();
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
