(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        HeaderSticky: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            if ($('.header').hasClass('sticky')) {
              var offset = $('.header').offset();
              var header_height = 0;
              if ($(window).width() < 991) {
                offset = $('.header-mobile').offset();
                header_height = $('.header-mobile').height();
              } else {
                offset = $('.header-desktop').offset();
                header_height = $('.header-desktop').height();
              }
              $('.header.sticky').headroom(
                  {
                    offset: offset.top,
                    onTop: function() {
                      if ($('.header-logo-image').attr('data-logo') !=
                          undefined) {
                        $('.header-logo-image').
                            attr('src',
                                $('.header-logo-image').attr('data-logo'));
                      }
                      if ($('.header-logo-image').attr('data-logo-retina') !=
                          undefined) {
                        $('.header-logo-image').
                            attr('srcset',
                                $('.header-logo-image').
                                    attr('data-logo-retina') +
                                ' 2x');
                      }
                      if (!$('.header').hasClass('style-overlay') &&
                          !$('.header').hasClass('layout-02')) {
                        $('html').css({paddingTop: 0});
                      }
                    },
                    onNotTop: function() {
                      if ($('.header-logo-image').attr('data-sticky') !=
                          undefined) {
                        $('.header-logo-image').
                            attr('src',
                                $('.header-logo-image').attr('data-sticky'));
                      }
                      if ($('.header-logo-image').attr('data-sticky-retina') !=
                          undefined) {
                        $('.header-logo-image').
                            attr('srcset',
                                $('.header-logo-image').
                                    attr('data-sticky-retina') +
                                ' 2x');
                      }
                      if (!$('.header').hasClass('style-overlay') &&
                          !$('.header').hasClass('layout-02')) {
                        $('html').css({paddingTop: header_height});
                      }
                    },
                  },
              );
            }
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
