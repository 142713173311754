(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        PortfolioFeatured: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            jQuery('.insight-portfolio-featured--page li').
                on('click', function() {
                  var data_filter = jQuery(this).data('filter');
                  jQuery(this).
                      closest('.insight-portfolio-featured--page').
                      find('li').
                      removeClass('active');
                  jQuery(this).addClass('active');
                  jQuery(this).
                      closest('.insight-portfolio-featured').
                      find(
                          '.insight-portfolio-featured-items .insight-portfolio-featured-item').
                      slideUp();
                  jQuery(this).
                      closest('.insight-portfolio-featured').
                      find(
                          '.insight-portfolio-featured-items .insight-portfolio-featured-item-' +
                          data_filter).
                      slideDown();
                });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
