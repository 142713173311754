(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        LightboxGallery: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            jQuery('.lightbox-gallery').lightGallery({
              selector: 'a',
              thumbnail: true,
              animateThumb: false,
              showThumbByDefault: false,
            });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
