(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        PortfolioFeaturedFull: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            var self = this;
            self.full_height();

            jQuery(window).on('resize', function() {
              self.full_height();
            });
          },

          full_height: function() {
            var ww = $(window).width();
            var wh = 0;
            if (ww > 991) {
              wh = $(window).height();
            } else {
              wh = $(window).height() - $('.header-mobile').height();
            }
            var row_fh = $('.content-full-height');
            row_fh.css('height', wh + 'px');
            var row_fh_h = $('.content-full-height').height();
            row_fh.find('.wpb_column').css('height', row_fh_h + 'px');
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
