(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        Woo: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            jQuery('.woo-single-images .woocommerce-main-image').lightGallery({
              selector: 'a',
              thumbnail: true,
              animateThumb: false,
              showThumbByDefault: false,
            });

            jQuery('.woo-single-images .woocommerce-main-image').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              fade: true,
              asNavFor: '.woo-single-images .thumbnails',
            });

            jQuery('.woo-single-images .thumbnails').slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              asNavFor: '.woo-single-images .woocommerce-main-image',
              dots: false,
              arrows: false,
              centerMode: false,
              focusOnSelect: true,
            });

            if (jQuery('.related .woo-products .product').length > 3) {
              jQuery('.related .woo-products').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
              });
            }
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
