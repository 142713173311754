(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        ProgressBar: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            $('[data-progress-width]').each(function($index) {
              $(this).inViewport(function(px) {
                if (px) {
                  var $this = $(this);
                  var time = 1 + (
                      $index / 2
                  );
                  var css = '-webkit-transition: all ' + time +
                      's cubic-bezier(0.645, 0.045, 0.01, 0.05);transition: all ' +
                      time + 's cubic-bezier(0.645, 0.045, 0.355, 0.1)';
                  $this.attr('style', css).
                      css({width: $this.data('progress-width') + '%'});
                }
              });
            });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
