(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        HeaderSide: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            var self = this;
            self.header_side();

            jQuery(window).on('resize', function() {
              if (jQuery('body').hasClass('header-layout-02')) {
                self.header_side();
              }
            });
          },

          header_side: function() {
            var $contentWidth = jQuery('#page').width();
            var $boxWidth = jQuery('#main').outerWidth();
            var $spacing = (
                $contentWidth - $boxWidth
            ) / 2;
            var rows = jQuery('#main .entry-content').children('.vc_row');
            rows.each(function() {
              if (jQuery(this).attr('data-vc-full-width')) {
                jQuery(this).css({
                  left: -$spacing + 'px',
                  width: $contentWidth + 'px',
                });
                if (!jQuery(this).attr('data-vc-stretch-content')) {
                  jQuery(this).css({
                    paddingLeft: $spacing,
                    paddingRight: $spacing,
                  });
                }
              }
            });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
