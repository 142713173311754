(
    function(insight, $) {
      function insightOnReady() {
        if (typeof insight.SearchBox !== 'undefined') {
          insight.SearchBox.init();
        }

        if (typeof insight.SearchBoxMobile !== 'undefined') {
          insight.SearchBoxMobile.init();
        }

        if (typeof insight.HeaderSticky !== 'undefined') {
          insight.HeaderSticky.init();
        }

        if (typeof insight.HeaderSide !== 'undefined') {
          insight.HeaderSide.init();
        }

        if (typeof insight.RightSide !== 'undefined') {
          insight.RightSide.init();
        }

        if (typeof insight.LightboxVideo !== 'undefined') {
          insight.LightboxVideo.init();
        }

        if (typeof insight.ProgressBar !== 'undefined') {
          insight.ProgressBar.init();
        }

        if (typeof insight.Counter !== 'undefined') {
          insight.Counter.init();
        }

        if (typeof insight.Carousel !== 'undefined') {
          insight.Carousel.init();
        }

        if (typeof insight.Accordion !== 'undefined') {
          insight.Accordion.init();
        }

        if (typeof insight.LightboxGallery !== 'undefined') {
          insight.LightboxGallery.init();
        }

        if (typeof insight.Page404 !== 'undefined') {
          insight.Page404.init();
        }

        if (typeof insight.ScrollUp !== 'undefined') {
          insight.ScrollUp.init();
        }

        if (typeof insight.Gmaps !== 'undefined') {
          insight.Gmaps.init();
        }

        if (typeof insight.PortfolioFilter !== 'undefined') {
          insight.PortfolioFilter.init();
        }

        if (typeof insight.PortfolioCarousel !== 'undefined') {
          insight.PortfolioCarousel.init();
        }

        if (typeof insight.PortfolioFeatured !== 'undefined') {
          insight.PortfolioFeatured.init();
        }

        if (typeof insight.PortfolioFeaturedFull !== 'undefined') {
          insight.PortfolioFeaturedFull.init();
        }

        if (typeof insight.Animate !== 'undefined') {
          insight.Animate.init();
        }

        if (typeof insight.SmoothMenu !== 'undefined') {
          insight.SmoothMenu.init();
        }

        if (typeof insight.SmartMenu !== 'undefined') {
          insight.SmartMenu.init();
        }

        if (typeof insight.Woo !== 'undefined') {
          insight.Woo.init();
        }
      }

      $(document).ready(function() {
        insightOnReady();
      });
    }.apply(this, [window.insight, jQuery])
);
