(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        ScrollUp: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            var $window = $(window);
            var $scrollup = $('.scrollup');

            $window.scroll(function() {
              if ($window.scrollTop() > 100) {
                $scrollup.addClass('show');
              } else {
                $scrollup.removeClass('show');
              }
            });

            $scrollup.on('click', function(evt) {
              $('html, body').animate({scrollTop: 0}, 600);
              evt.preventDefault();
            });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
