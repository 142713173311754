(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        PortfolioFilter: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            setTimeout(function() {
              jQuery(window).trigger('resize');
            }, 100);

            // Portfolio filter
            jQuery('.insight-portfolio-filter').each(function() {
              var $this = jQuery(this);
              var thisID = $this.data('id');
              var $thisItems = $('#' + thisID + ' .insight-items');
              var offsetTop = jQuery(this).offset().top;
              var hasPaging = $this.data('paging');
              var itemsPerPage = $this.data('perpage');
              var itemSelector = '.insight-item';
              var currentFilter = '*';
              var filterSelector = '#' + thisID;
              var filterAttribute = 'class';
              //start isotope
              $thisItems.isotope({
                itemSelector: itemSelector,
                masonry: {
                  columnWidth: '.grid-sizer',
                  isFitWidth: true,
                },
              });
              if (hasPaging == 'yes') {
                var currentPage = 1;
                var currentNumberPages = 1;
                var pageAttribute = 'data-page';
                var pagerClass = 'insight-pager';
                insight_set_pagination(itemSelector, currentFilter,
                    filterAttribute, itemsPerPage, pageAttribute, pagerClass,
                    currentNumberPages, currentPage, $thisItems);
                insight_go_to_page(1, itemSelector, currentFilter,
                    filterAttribute, pageAttribute, currentPage, $thisItems);
                // Filter
                $(filterSelector).on('click', ' .insight-filter a', function() {
                  currentFilter = $(this).attr('data-filter');
                  $(filterSelector + ' .insight-filter a').
                      removeClass('active');
                  $(this).addClass('active');
                  insight_set_pagination(itemSelector, currentFilter,
                      filterAttribute, itemsPerPage, pageAttribute, pagerClass,
                      currentNumberPages, currentPage, $thisItems);
                  insight_go_to_page(1, itemSelector, currentFilter,
                      filterAttribute, pageAttribute, currentPage, $thisItems);
                });
                // Responsive
                $(window).resize(function() {
                  insight_set_pagination(itemSelector, currentFilter,
                      filterAttribute, itemsPerPage, pageAttribute, pagerClass,
                      currentNumberPages, currentPage, $thisItems);
                  insight_go_to_page(1, itemSelector, currentFilter,
                      filterAttribute, pageAttribute, currentPage, $thisItems);
                });
                // Paging
                $(filterSelector).on('click', '.insight-pager a', function() {
                  jQuery('body').animate({scrollTop: offsetTop});
                  $(filterSelector + ' .insight-pager a').removeClass('active');
                  $(this).addClass('active');
                });
              } else {
                // Filter
                $(filterSelector).on('click', ' .insight-filter a', function() {
                  $(filterSelector + ' .insight-filter a').
                      removeClass('active');
                  $(this).addClass('active');
                  currentFilter = $(this).attr('data-filter');
                  var selector = itemSelector;
                  selector += (
                      currentFilter != '*'
                  ) ? '[' + filterAttribute + '~="' + currentFilter + '"]' : '';
                  insight_change_filter(selector, $thisItems);
                });
              }
            });

            function insight_change_filter(selector, $thisItems) {
              $thisItems.isotope({
                filter: selector,
              });
            }

            function insight_go_to_page(
                page, itemSelector, currentFilter, filterAttribute,
                pageAttribute, currentPage, $thisItems) {
              var selector = itemSelector;
              currentPage = page;
              selector += (
                  currentFilter != '*'
              ) ? '[' + filterAttribute + '~="' + currentFilter + '"]' : '';
              selector += '[' + pageAttribute + '="' + currentPage + '"]';

              insight_change_filter(selector, $thisItems);
            }

            function insight_set_pagination(
                itemSelector, currentFilter, filterAttribute, itemsPerPage,
                pageAttribute, pagerClass, currentNumberPages, currentPage,
                $thisItems) {
              var item = 1;
              var page = 1;
              var selector = itemSelector;
              selector += (
                  currentFilter != '*'
              ) ? '[' + filterAttribute + '~="' + currentFilter + '"]' : '';
              $thisItems.children(selector).each(function() {
                if (item > itemsPerPage) {
                  page++;
                  item = 1;
                }
                jQuery(this).attr(pageAttribute, page);
                item++;
              });
              currentNumberPages = page;
              var $isotopePager = (
                  jQuery('.' + pagerClass).length == 0
              ) ?
                  jQuery('<div class="' + pagerClass + '"></div>') :
                  jQuery('.' + pagerClass);
              $isotopePager.html('');
              if (currentNumberPages > 1) {
                for (var i = 0; i < currentNumberPages; i++) {
                  var $pager = jQuery(
                      '<a href="javascript:void(0);" ' + pageAttribute + '="' +
                      (
                          i + 1
                      ) + '"></a>');
                  $pager.html(i + 1);
                  $pager.on('click', function() {
                    var page = jQuery(this).eq(0).attr(pageAttribute);
                    jQuery(this).eq(0).addClass('active');
                    insight_go_to_page(page, itemSelector, currentFilter,
                        filterAttribute, pageAttribute, currentPage,
                        $thisItems);
                  });
                  $pager.appendTo($isotopePager);
                }
                $isotopePager.find('a[data-page="1"]').addClass('active');
              }
              $thisItems.after($isotopePager);
            }
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
