(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        Counter: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            $('.insight-counter').find('.number span').each(function() {
              var v = $(this).html();
              var o = new Odometer({
                el: $(this)[0],
                value: 0,
              });
              o.render();
              $(this).inViewport(function(px) {
                if (px) {
                  o.update(v);
                }
              });
            });
            $(window).trigger('scroll');
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
