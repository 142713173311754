(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        PortfolioCarousel: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            jQuery('.insight-portfolio-carousel').each(function() {
              var data_id = jQuery(this).data('id');
              jQuery(this).find('.insight-filter a').on('click', function(e) {
                var data_filter = jQuery(this).data('filter');
                jQuery('#' + data_id + ' .insight-filter a').
                    removeClass('active');
                jQuery(this).addClass('active');
                jQuery('#' + data_id + ' .insight-items').
                    slick('slickUnfilter');
                if (data_filter != '*') {
                  jQuery('#' + data_id + ' .insight-items').
                      slick('slickFilter', '.' + data_filter);
                }
                e.preventDefault();
              });
            });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
