(
    function(insight, $) {
      insight = insight || {};
      $.extend(insight, {

        Accordion: {

          init: function() {
            this.build();
            return this;
          },

          build: function() {
            $.fn.insightAccordion = function() {
              var thisAcc = this;
              var thisType = this.data('type');
              thisAcc.find('.title').on('click', function() {
                if (thisType == 'accordion') {
                  thisAcc.find('.item').removeClass('active');
                  $(this).parent().addClass('active');
                } else {
                  $(this).parent().toggleClass('active');
                }
              });
            };
            $('[data-insight-accordion="true"]').each(function() {
              $(this).insightAccordion();
            });
          },
        },

      });
    }
).apply(this, [window.insight, jQuery]);
